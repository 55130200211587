import React, { JSX, useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import ConnectionListView from './connections/ConnectionListView';
import ModuleConfigurator from '../../components/connection/ModuleConfigurator';
import { AppContext } from '../../state/appContext';
import { bodyMaxHeight } from '../../theme/theme';

function ModuleConfigurationView({
  moduleName,
}: {
  moduleName: SupportedModule;
}): JSX.Element {
  const [appState] = useContext(AppContext);
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState<
    ModuleConfiguration | undefined
  >();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const connection = appState.integrationConnections.find(
      c => c.moduleId === moduleName
    );

    if (connection !== undefined) {
      setShowAlert(true);
      setConfiguration(connection);
    } else {
      setShowAlert(false);
      setConfiguration(undefined);
    }
  }, [appState.integrationConnections, moduleName]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        <Box flexGrow={1} flexDirection={'row'} display={'flex'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            aria-label="Back"
            component="label"
            size={'small'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          {showAlert &&
            configuration !== undefined &&
            configuration.enabled && (
              <Alert severity="success" onClose={() => setShowAlert(false)}>
                <AlertTitle>Enabled!</AlertTitle>
                Module has been successfully enabled
              </Alert>
            )}
          {showAlert &&
            configuration !== undefined &&
            !configuration.enabled && (
              <Alert severity="warning" onClose={() => setShowAlert(false)}>
                <AlertTitle>Not enabled</AlertTitle>
                Module has not been enabled yet
              </Alert>
            )}
          <ModuleConfigurator moduleName={moduleName} />
        </Box>
      </Grid>
    </Grid>
  );
}

const IntegrationsView = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<ConnectionListView />} />
      <Route
        path="/internal"
        element={<ModuleConfigurationView moduleName={'internal'} />}
      />
      <Route
        path="/osuuspankki"
        element={<ModuleConfigurationView moduleName={'osuuspankki'} />}
      />
      <Route
        path="/netsuite"
        element={<ModuleConfigurationView moduleName={'netsuite'} />}
      />
      <Route
        path="/aws"
        element={<ModuleConfigurationView moduleName={'aws'} />}
      />
      <Route
        path="/delfoi"
        element={<ModuleConfigurationView moduleName={'delfoi'} />}
      />
      <Route
        path="/vari-pdm"
        element={<ModuleConfigurationView moduleName={'vari-pdm'} />}
      />
      <Route
        path="/hubspot"
        element={<ModuleConfigurationView moduleName={'hubspot'} />}
      />
      <Route
        path="/mrpeasy"
        element={<ModuleConfigurationView moduleName={'mrpeasy'} />}
      />
      <Route
        path="/skyplanner"
        element={<ModuleConfigurationView moduleName={'skyplanner'} />}
      />
      <Route
        path="/cargoson"
        element={<ModuleConfigurationView moduleName={'cargoson'} />}
      />
      <Route
        path="/ongoing"
        element={<ModuleConfigurationView moduleName={'ongoing'} />}
      />
      <Route
        path="/adeona"
        element={<ModuleConfigurationView moduleName={'adeona'} />}
      />
      <Route
        path="/salesforce"
        element={<ModuleConfigurationView moduleName={'salesforce'} />}
      />
      <Route
        path="/airtable"
        element={<ModuleConfigurationView moduleName={'airtable'} />}
      />
      <Route
        path="/netvisor"
        element={<ModuleConfigurationView moduleName={'netvisor'} />}
      />
      <Route
        path="/pagero"
        element={<ModuleConfigurationView moduleName={'pagero'} />}
      />
      <Route
        path="/clickup"
        element={<ModuleConfigurationView moduleName={'clickup'} />}
      />
      <Route
        path="/nordea-business"
        element={<ModuleConfigurationView moduleName={'nordea-business'} />}
      />
      <Route
        path="/microsoft-graph"
        element={<ModuleConfigurationView moduleName={'microsoft-graph'} />}
      />
    </Routes>
  );
};

export default IntegrationsView;
